import React from 'react'
// import {
//     Button,
//     ButtonGroup,
//     Fab

// } from '@material-ui/core';

import styles from './index.module.css'

import facebookIcon from './images/thin-lines/facebook.png'
import twitterIcon from './images/thin-lines/twitter.png'
import instagramIcon from './images/thin-lines/instagram.png'


export default function SocialLinks() {
    return (
        <div className={styles.socialLinks}>
            <a href="//www.facebook.com/NaomiAchuWorldwide" target="_blank" rel="noopener noreferrer">
                <img src={facebookIcon} alt="Facebook icon" />
            </a>
            <a href="//twitter.com/naomiachu" target="_blank" rel="noopener noreferrer">
                <img src={twitterIcon} alt="Twitter icon" />
            </a>
            <a href="//www.instagram.com/iamnaomiachu" target="_blank" rel="noopener noreferrer">
                <img src={instagramIcon} alt="Instagram icon" />
            </a>
            {/* <a href="https://anchor.fm/franchised" target="_blank" rel="noopener noreferrer">
                <img src={anchorIcon} alt="Anchor FM icon" />
            </a>
            <a href="//anchor.fm/s/dcf1798/podcast/rss" target="_blank" rel="noopener noreferrer">
                <img src={rssIcon} alt="RSS icon" />
            </a> */}
        </div>
    )
}