import React, { useState } from 'react'
import { Link, View, NotFoundBoundary } from 'react-navi'
// import MailchimpSubscribe from "react-mailchimp-subscribe"

// import axios from 'axios';
// import { useTheme } from '@material-ui/core/styles';
// import useMediaQuery from '@material-ui/core/useMediaQuery';


import SocialLinks from 'components/SocialLinks'
// import SoundCloudPlayer from 'components/SoundCloudPlayer'



// import { MDXProvider } from '@mdx-js/react';
// import Button from '@material-ui/core/Button';
import styles from './Shell.module.css';
// import flyerEp1 from './images/flyer-ep-1.jpg'
import logoImg from './images/logo.png'

import { makeStyles } from '@material-ui/core/styles';

import {
  Box,
  // ClickAwayListener,
  AppBar,
  Toolbar,
  // Typography,
  Grid,
  Drawer,
  // SwipeableDrawer,
  // Avatar,
  IconButton,
  // Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';

import {
  MailOutline,
  Event as EventIcon,
  Group as GroupIcon,
  Home as HomeIcon,
  Menu as MenuIcon,
  PartyMode as CameraIcon,
  Headset as MusicIcon
  // Dashboard as DashboardIcon,
  // AssignmentTurnedInOutlined as AssignmentIcon
} from '@material-ui/icons';



// // MAIL CHIMP
// const url = "//gmail.us3.list-manage.com/subscribe/post?u=653596f6535bfdeb18d350032&amp;id=771c57f72c";
// const CustomForm = ({ status, message, onValidated }) => {


//   let email; // , name;
//   const submit = () =>
//     email &&
//     // name &&
//     email.value.indexOf("@") > -1 &&
//     onValidated({
//       EMAIL: email.value,
//       // NAME: name.value
//     });

//   return (
//     <div
//       className={styles.newsletterShell}
//     >
//       {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
//       {status === "error" && (
//         <div
//           style={{ color: "red" }}
//           dangerouslySetInnerHTML={{ __html: message }}
//         />
//       )}
//       {status === "success" && (
//         <div
//           style={{ color: "green" }}
//           dangerouslySetInnerHTML={{ __html: message }}
//         />
//       )}
//       {/*  <input
//         style={{ fontSize: "2em", padding: 5 }}
//         ref={node => (name = node)}
//         type="text"
//         placeholder="Your name"
//       /> */}

//       <input
//         className={styles.newsletterInput}
//         ref={node => (email = node)}
//         type="email"
//         placeholder="Your email"
//       />

//       <button className={styles.newsletterBtn} onClick={submit}>
//         Sign Up
//       </button>
//     </div>
//   );
// };

// DRAWER
const drawerWidth = 240;


const useStyles = makeStyles(theme => ({
  roleName: {
    textTransform: 'capitalize',
    fontSize: '12px',
    color: '#999',
    marginBottom: 10
  },
  grow: {
    flexGrow: 1,
  },
  logoIcon: {
    width: 32,
    display: 'inline-block'
  },
  logoText: {
    display: 'inline-block',
    paddingLeft: 10,
    paddingTop: 10
  },
  avatar: {
    margin: 10,
    height: 60,
    width: 60
  },
  drawerShell: {
    backgroundColor: '#f00',
    width: '100%'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    // width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerList: {
    width: drawerWidth,
  },
  content: {
    // backgroundColor: '#f4f6f8'
    // flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
}));


function Shell(props) {

  // const theme = useTheme();
  // const isMobileBreakpoint = useMediaQuery(theme.breakpoints.up('md'));


  const classes = useStyles();
  // let loadingRoute = useLoadingRoute()


  // const [isUser, setIsUser] = useState(null);
  // const [isUserPhoto, setIsUserPhoto] = useState(null);
  // const [isUserRole, setIsUserRole] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false) // useState(!isMobileBreakpoint)



  const handleDrawerOpen = () => {
    //   console.log(isDrawerOpen)
    setIsDrawerOpen(!isDrawerOpen)
  }


  const MenuItems = [
    {
      text: 'Home',
      href: '/',
      icon: HomeIcon
    },
    {
      text: 'Music',
      href: '/music',
      icon: MusicIcon
    },
    {
      text: 'Gallery',
      href: '/gallery',
      icon: CameraIcon
    },
    {
      text: 'Events',
      href: '/events',
      icon: EventIcon
    },
    {
      text: 'About',
      href: '/about',
      icon: GroupIcon
    },
    {
      text: 'Contact',
      href: '/contact',
      icon: MailOutline
    },

  ]


  return (
    <div>

      <AppBar position="fixed">
        <Toolbar>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="center">

            <Grid item xs={3} sm={6} md={8}>
              <Link href="/">
                <img
                  src={logoImg}
                  className={styles.logo}
                  alt="logo"
                />
              </Link>

            </Grid>

            <Grid item xs={9} sm={6} md={4}>
              <Grid container justify="flex-end">
                <Grid item>
                  <SocialLinks />

                </Grid>
                <Grid item>
                  <IconButton
                    color="inherit"
                    edge="end"
                    onClick={handleDrawerOpen}
                  >
                    <MenuIcon />
                  </IconButton>
                  {/* <HamburgerMenu
                    isOpen={this.state.open}
                    menuClicked={this.handleClick.bind(this)}
                    width={18}
                    height={15}
                    strokeWidth={1}
                    rotate={0}
                    color='black'
                    borderRadius={0}
                    animationDuration={0.5}
                  /> */}
                </Grid>
              </Grid>
            </Grid>

          </Grid>

        </Toolbar>

        <div className={classes.drawerShell} onClick={handleDrawerOpen} onKeyDown={handleDrawerOpen}>
          <Drawer
            open={isDrawerOpen} // width < 960 ? isDrawerOpen : true

            className={classes.drawer}
            anchor="right"
            variant="temporary"

            // variant={isMobileBreakpoint ? 'persistent' : 'temporary'}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <div className={classes.toolbar} />


            {/* 
              <Divider />
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center">

                   <Typography variant="h6" noWrap>
                {'NAOMI ACHU'}
              </Typography>
              <Typography variant="subtitle1" noWrap className={classes.roleName}>
                {'Queen of Bamenda'}
              </Typography>

            </Grid> 
             <Divider />
            */}



            <List className={classes.drawerList}>
              {MenuItems.map((itm, index) => (
                <ListItem button key={itm.text} href={itm.href} activeClassName='active' component={Link}>
                  <ListItemIcon>
                    <itm.icon />
                  </ListItemIcon>
                  <ListItemText primary={itm.text} />
                </ListItem>
              ))}
            </List>

            {/* <Divider /> */}




          </Drawer>
        </div>
      </AppBar>

      <Grid container>

        <Grid item xs={12}>
          <main className={classes.content}>

            <NotFoundBoundary render={renderNotFound}>
              <Toolbar />
              <View />
            </NotFoundBoundary>


            {/* <NotFoundBoundary render={renderNotFound}>
              <div
                // Only add the `active` class to this element while the
                // next page is loading, triggering a CSS animation to
                // show or hide the loading bar.
                className={`
              App-loading-indicator
              ${loadingRoute ? 'active' : ''}
            `}
              />
              <MDXProvider components={{
                // Use Navi's <Link> component to render links in
                // Markdown files, ensuring navigation is handled by Navi.
                a: Link,
              }}>
                <Toolbar />
                <View />

              </MDXProvider>
            </NotFoundBoundary> */}


          </main>

        </Grid>

        <Grid item xs={12}>
          <footer className={styles.footer}>

            <Box className={styles.newsletter} textAlign="center">

              {/* <Typography variant="h4" className={styles.newsletterTitle}>
                Naomi Newsletter
            </Typography>



              <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => (
                  <CustomForm
                    status={status}
                    message={message}
                    onValidated={formData => subscribe(formData)}
                  />

                )}
              /> */}

            </Box>
            <Box textAlign="center">

              <SocialLinks />
              <br />

            </Box>


          </footer>
        </Grid>
        <Grid item xs={12} className={styles.copyright}>
          <Box textAlign="center">
            &copy; 2021 Naomi Achu Worldwide
          </Box>
        </Grid>

      </Grid>

      {/* <div id="base">
        <SoundCloudPlayer />
      </div> */}


    </div>


  );
}

/* <div>
                  <SimpleForm onSubmitted={formData => subscribe(formData)} />
                  {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
                  {status === "error" && <div style={{ color: "red" }} dangerouslySetInnerHTML={{ __html: message }} />}
                  {status === "success" && <div style={{ color: "green" }}>Subscribed !</div>}
                </div> */

// Note that create-react-navi-app will always show an error screen when this
// is called. This is because the underlying react-scripts package show
// the error screen when a NotFoundError is thrown, even though it's caught
// by <NotFoundBoundary>. To see the error rendered by this function,
// you'll just need to close the error overlay with the "x" at the top right.
function renderNotFound() {
  return (
    <div className='App-error'>
      <h1>404 - Not Found</h1>
    </div>
  )
}

export default Shell;