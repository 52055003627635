import React from 'react'
import {
    CircularProgress

} from '@material-ui/core';

import styles from './index.module.css'



export default function Loader() {
    return (
        <div className={styles.shellContainer}>
            <div className={styles.progressContainer}>
                <CircularProgress color={"secondary"} size={'100%'} disableShrink />

            </div>
        </div>
    )
}