export default {
    palette: {
        primary: {
            main: '#000',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#e822d1',
            contrastText: '#FFFFFF'
        },
        error: {
            main: '#ffffff'
        }
    },
    text: {
        primary: {
            main: '#ffffff'
        }
    },
    typography: {
        useNextVariants: true
    }
}
